import React from 'react';
import './App.css';
import rugg_jpg from "./rugg.png"


const App = () => {
  return (
    <div className="app-container">
      <header className="header">
        <h1 className="title">RugPullCoin</h1>
        <p className="subtitle">"In memory of all the rug pulls that took us by surprise."</p>
      </header>

      <main className="content">
        <div className="rug-pull-display">
          <h2 className="section-title">A Creative Tribute to Rug Pulls</h2>
          <p className="description">
            "RugPullCoin" is a satirical meme coin, reminding everyone of the moments we
            were left holding the bag. Let's laugh through the tears and learn from the past.
          </p>

          <div className="rug-pull-image">
            <img
              src={rugg_jpg}
              alt="Rug Pull Tribute"
              className="image"
            />
          </div>
        </div>

        <div className="social-links">
          <h3 className="social-title">Join the Conversation</h3>
          <p className="social-description">Connect with us on social media and share your rug pull stories:</p>
          <div className="social-buttons">
            <a
              href="https://x.com/ruggpulltribute"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter-button"
              style={{ fontSize: '0.8rem', padding: '5px 10px', display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
                alt="Twitter"
                className="social-icon"
                style={{ width: '16px', height: '16px', marginRight: '5px' }}
              />
              Follow us on Twitter
            </a>
            {/* <a
              href="https://t.me"
              target="_blank"
              rel="noopener noreferrer"
              className="telegram-button"
              style={{ fontSize: '0.8rem', padding: '5px 10px', display: 'inline-flex', alignItems: 'center', textDecoration: 'none', marginLeft: '10px' }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/2111/2111646.png"
                alt="Telegram"
                className="social-icon"
                style={{ width: '16px', height: '16px', marginRight: '5px' }}
              />
              Join us on Telegram
            </a> */}
          </div>
        </div>
      </main>

      <footer className="footer">
        <p className="footer-text">© 2024 RugPullCoin. All rights pulled.</p>
      </footer>
    </div>
  );
};

export default App;
